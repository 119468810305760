<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" scrollable width="800" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
      <v-card>
        <v-toolbar class="blue-grey" dark>
          <v-toolbar-title>
            Terminal
          </v-toolbar-title>

          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="grey lighten-1"
          />

          <v-spacer />

          <v-chip label style="font-family: monospace; font-weight: bold; word-spacing: -5px;" color="rgb(255,255,255,0.3)" class="white--text">{{ app.repository.split('/').join(' / ') }} @ {{ stage }}</v-chip>

          <v-chip label style="font-family: monospace; font-weight: bold; word-spacing: -5px;" color="rgb(255,255,255,0.3)" class="red darken-2 ml-3"><v-icon left>view_in_ar</v-icon>{{ container }}</v-chip>

          <v-chip label style="font-family: monospace; font-weight: bold; word-spacing: -5px;" color="rgb(255,255,255,0.3)" class="indigo ml-3"><v-icon left>terminal</v-icon>/bin/{{ command }}</v-chip>

          <v-btn icon class="ml-2" color="white" @click="close()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text style="text-align: justify; background-color: black;" class="pt-6">
          <div ref="terminal"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import '@xterm/xterm/css/xterm.css'
import { Terminal } from '@xterm/xterm'
import { AttachAddon } from '@xterm/addon-attach'
import { FitAddon } from '@xterm/addon-fit'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      app: {
        repository: ''
      },
      stage: '',
      container: '',
      cluster: '',
      port: 65500,
      term: null,
      socket: null,
      command: 'bash',
      token: ''
    }
  },
  methods: {
    open (app, stage, container, command, port) {
      this.app = app
      this.stage = stage
      this.container = container
      this.command = command
      this.cluster = this.app.stages[this.stage].cluster
      this.port = port

      this.loading = true
      this.dialog = true

      const url = 'wss://' + this.cluster.replace(/\/+$/, '') + ':' + this.port + '/' + this.app.repository + '/' + this.stage + '/' + this.container + '/' + this.command

      console.log(url)

      this.socket = new WebSocket(url, this.token)

      this.socket.onopen = () => {
        this.socket.send('export LANG=C.UTF-8\n')
        this.socket.send('export LC_ALL=C.UTF-8\n')
        this.socket.send('export TERM="xterm-256color"\n')
        this.socket.send('alias ls="ls --color=auto"\n')
        this.socket.send('echo -e "\\033[2J\\033[H"\n')

        const term = new Terminal({
          rendererType: 'canvas',
          rows: 40,
          cols: 100,
          convertEol: true,
          disableStdin: false,
          windowsMode: true,
          cursorStyle: 'underline',
          cursorBlink: true,
          theme: {
            foreground: '#ECECEC',
            background: '#000000',
            cursor: 'help',
            lineHeight: 20
          }
        })

        let buffer = ''

        term.onData(data => {
          if (data === '\x04') {
            this.close()
          } else if (data === '\r') {
            if (buffer.trim() === 'exit') {
              this.close()
            }
            buffer = ''
          } else {
            buffer += data
          }
        })

        const attach = new AttachAddon(this.socket)
        const fit = new FitAddon()

        term.loadAddon(attach)
        term.loadAddon(fit)

        term.open(this.$refs.terminal)

        fit.fit()
        term.focus()

        this.loading = false

        this.term = term
      }
    },
    close () {
      this.dialog = false

      if (this.term !== null) this.term.dispose()
      if (this.socket !== null) {
        this.socket.send('exit\n')
        this.socket.close()
      }

      this.term = null
      this.socket = null
    }
  },
  beforeMount () {
    this.token = this.$localStorage.get('user').token
  },
  beforeUnmount () {
    this.close()
  }
}
</script>
