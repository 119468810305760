<template>
  <v-card :width="width" flat tile color="grey lighten-4">
    <v-card-title>
      <v-avatar tile class="mr-3">
        <font-awesome-icon color="grey" :icon="app.code && app.code.icon ? app.code.icon : (app.more ? app.more.icon : 'fas fa-code-branch')" />
      </v-avatar>
      <v-tooltip :color="status.color + ' lighten-5'" right>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-badge inline dot :color="status.type">
              <div style="font-family: monospace; overflow-x: hidden; overflow-y: visible; white-space: nowrap; max-width: 250px; height: 32px;" class="font-weight-bold pt-2">{{ app.repository.split('/')[1] }}</div>
            </v-badge>
          </div>
        </template>
        <span :class="status.color + '--text'">{{ status.text }}</span>
      </v-tooltip>
      <v-spacer />
      <v-icon v-if="!app.bug">hourglass_empty</v-icon>
      <v-tooltip v-else left :color="app.bug.errors > 10 ? 'error' : (app.bug.errors > 0 ? 'warning' : 'success')">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-badge
              overline
              left
              inline
              :color="app.bug.errors > 10 ? 'error' : (app.bug.errors > 0 ? 'warning' : 'success')"
              :content="app.bug.errors.toString()">
              <v-btn large icon @click="bug()">
                <v-icon>bug_report</v-icon>
              </v-btn>
            </v-badge>
          </div>
        </template>
        <span>{{ app.bug.errors }} erro(s) em {{ app.bug.issues }} issue(s)</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text style="background-color: white;" v-if="[ 'CREATING', 'ACTIVATING', 'DEACTIVATING' ].includes(app.status)">
      <v-img src="@/assets/wait.png" height="150" contain style="top: 10px;;" />
    </v-card-text>
    <v-card-text class="text-center py-6" style="background-color: white;" v-if="[ 'DEACTIVATED', 'UNDEFINED' ].includes(app.status)">
      <v-icon size="54px" color="error" class="my-8">dangerous</v-icon>
    </v-card-text>
    <!--
    status de stages:
    DRAFT
    VALIDATING
    INVALID
    VALID
    DEPLOYING
    ACTIVE
    UNDEPLOYING
    INACTIVATED
    -->
    <v-list dense v-if="app.status === 'ACTIVE'" class="py-0">
      <div class="mb-2" style="background-color: #FFF; display: block; height: 8px; overflow: hidden;">
        <template v-if="app.code && app.code.languages && app.code.languages.length !== 0">
          <v-tooltip v-for="l in app.code.languages" :key="l.unix" :color="color(l.unix)" top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" :style="'background-color: ' + color(l.unix) + '75; width: ' + l.proportion + '%; height: 8px; float: left; cursor: help;'"></div>
            </template>
            <span>{{ l.label }} ({{ l.lines }} linhas)</span>
          </v-tooltip>
        </template>
      </div>
      <v-list-item v-for="(b, index) in ['release', 'beta', 'alpha']" :key="index" class="pl-2">
        <v-tooltip right attach v-if="$vuetify.breakpoint.smAndUp">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar v-bind="attrs" v-on="on" :color="stageStatus[app.stages[b].status].color + ' lighten-5'" size="28" rounded class="ml-1 mr-2" @click="showStageStatus(b)" style="cursor: pointer;"><v-icon small :color="stageStatus[app.stages[b].status].color">{{ stageStatus[app.stages[b].status].icon }}</v-icon></v-avatar>
          </template>
          <span>{{ stageStatus[app.stages[b].status].label }}</span>
        </v-tooltip>
        <v-avatar v-else :color="stageStatus[app.stages[b].status].color + ' lighten-5'" size="28" rounded class="ml-1 mr-2" @click="showStageStatus(b)" style="cursor: pointer;"><v-icon small :color="stageStatus[app.stages[b].status].color">{{ stageStatus[app.stages[b].status].icon }}</v-icon></v-avatar>
        <v-list-item-content>
          <v-list-item-title class="hidden-sm-and-down overline py-2">{{ b }}</v-list-item-title>
          <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
          <v-list-item-title class="hidden-md-and-up py-2" style="font-size: 18px;" v-html="stages[b].greek"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="app.stages[b].deploy">
          <v-list-item-action-text>
            <v-chip dark label small :color="stages[b].color" class="text-uppercase font-weight-medium">
              {{ app.stages[b].deploy }}
              <v-icon class="ml-2" small>rocket_launch</v-icon>
            </v-chip>
          </v-list-item-action-text>
        </v-list-item-action>
        <v-list-item-icon>
          <v-btn icon @click="settings(b)" :disabled="!project.maintainer || [ 'VALIDATING', 'DEPLOYING', 'UNDEPLOYING' ].includes(app.stages[b].status)"><v-icon>settings</v-icon></v-btn>
          <v-btn icon @click="health(b)" :disabled="!app.stages[b].deploy || [ 'UNDEPLOYING', 'INACTIVATED' ].includes(app.stages[b].status)"><v-icon>monitor_heart</v-icon></v-btn>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon :disabled="app.stages[b].links.length === 0 || app.stages[b].status === 'INACTIVATED'" v-bind="attrs" v-on="on"><v-icon>link</v-icon></v-btn>
            </template>
            <v-list max-width="450px">
              <div v-for="(link, sindex) in app.stages[b].links" :key="link.port">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title><a :href="'http://' + link.permanent" target="_blank" style="text-decoration: none;">{{ link.permanent }}</a></v-list-item-title>
                    <v-list-item-subtitle v-if="link.subdomain && checkUrl(link.subdomain)">
                      <i>ou</i> <a :href="link.subdomain" target="_blank" style="text-decoration: none;">{{ link.subdomain }}</a>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="link.alias && checkUrl(link.alias)">
                      <i>ou</i> <a :href="link.alias" target="_blank" style="text-decoration: none;">{{ link.alias }}</a>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="link.subpath && checkUrl(link.subpath)">
                      <i>ou</i> <a :href="link.subpath" target="_blank" style="text-decoration: none;">{{ link.subpath }}</a>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon><v-icon color="success" x-large>public</v-icon></v-list-item-icon>
                </v-list-item>
                <v-divider v-if="sindex < app.stages[b].links.length - 1" :key="sindex" />
              </div>
            </v-list>
          </v-menu>
        </v-list-item-icon>
      </v-list-item>
    </v-list>

    <v-dialog v-model="wizard" max-width="800px" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
      <wizard-environment-wrapper @close="wizard = false" v-on="$listeners" :project="project" :app="app" :env="environment" :boilerplates="boilerplates" :clusters="clusters" :types="types" />
    </v-dialog>

    <build-health-dialog ref="health" v-on="$listeners" />

    <bug-dialog ref="bug" v-on="$listeners" />

    <status-dialog ref="status" v-on="$listeners" />
  </v-card>
</template>

<script>
import WizardEnvironmentWrapper from '@/components/WizardEnvironment.vue'
import BuildHealthDialog from '@/components/BuildHealth.vue'
import BugDialog from '@/components/AppBug.vue'
import StatusDialog from '@/components/AppStatus.vue'

import UtilHelper from '@/helpers/util.js'

import stc from 'string-to-color'

export default {
  mixins: [
    UtilHelper
  ],
  props: {
    project: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    app: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    width: {
      type: String,
      default: undefined
    },
    boilerplates: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    },
    clusters: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    types: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    }
  },
  components: {
    WizardEnvironmentWrapper,
    BuildHealthDialog,
    BugDialog,
    StatusDialog
  },
  data: () => ({
    wizard: false,
    environment: null,
    status: {
      icon: 'hourglass_empty',
      type: 'warning',
      text: 'Carregando...'
    },
    stageStatus: {
      DRAFT: { icon: 'edit', color: 'blue', label: 'Rascunho', description: 'Ainda não foi realizado deploy no cluster remoto. Enquanto estiver nesta fase, o cluster poderá ser alterado nas configurações da build.' },
      VALIDATING: { icon: 'rule', color: 'orange', label: 'Validando', description: 'A build foi configurada e está sendo validada. Somente após ter sido validada com êxito, um deploy poderá ser realizado.' },
      INVALID: { icon: 'warning', color: 'red', label: 'Inválido', description: 'A build foi configurada, mas houve algum problema detectado durante a validação ou no deploy. Verifique os logs (em seu e-mail) para mais detalhes.' },
      VALID: { icon: 'verified', color: 'teal', label: 'Válido', description: 'A build foi configurada e validada com sucesso. Agora é possível realizar o deploy no cluster remoto criando uma tag com o número da nova versão.' },
      DEPLOYING: { icon: 'cloud_upload', color: 'purple', label: 'Instanciando', description: 'A build foi configurada, validada e está sendo implantada no cluster remoto. Aguarde até que o deploy seja concluído.' },
      ACTIVE: { icon: 'cloud_done', color: 'green', label: 'Ativo', description: 'A build foi configurada, validada e implantada com sucesso no cluster remoto. O deploy foi concluído com êxito.' },
      UNDEPLOYING: { icon: 'cloud_download', color: 'deep-orange', label: 'Desinstanciando', description: 'A instância da build no cluster remoto está sendo removida. Aguarde a finalização.' },
      INACTIVATED: { icon: 'public_off', color: 'blue-grey', label: 'Inativo', description: 'A instância da build no cluster remoto foi removida com sucesso. O deploy foi desativado. Você pode reativar esta build reconfigurando-a.' }
    },
    stages: {
      alpha: { greek: '&alpha;', color: 'cyan darken-1' },
      beta: { greek: '&beta;', color: 'cyan darken-2' },
      release: { greek: '&rho;', color: 'cyan darken-3' }
    }
  }),
  beforeMount () {
    this.type()
  },
  watch: {
    app: {
      handler () {
        this.type()
      },
      deep: true
    }
  },
  methods: {
    type () {
      switch (this.app.status) {
        case 'ACTIVE':
          this.status.icon = 'done'
          this.status.type = 'success'
          this.status.text = 'Ativo!'
          this.status.color = 'green'

          break

        case 'DEACTIVATED':
          this.status.icon = 'close'
          this.status.type = 'error'
          this.status.text = 'App desativado!'
          this.status.color = 'red'

          break

        case 'CREATING':
          this.status.icon = 'construction'
          this.status.type = 'warning'
          this.status.text = 'Criando... por favor, aguarde!'
          this.status.color = 'orange'

          break

        case 'ACTIVATING':
          this.status.icon = 'file_upload'
          this.status.type = 'warning'
          this.status.text = 'Ativando... por favor, aguarde!'
          this.status.color = 'orange'

          break

        case 'DEACTIVATING':
          this.status.icon = 'file_download'
          this.status.type = 'error'
          this.status.text = 'Desativando... por favor, aguarde!'
          this.status.color = 'red'

          break

        case 'UNDEFINED':
        default:
          this.status.icon = 'error'
          this.status.type = 'error'
          this.status.text = 'Erro! Impossível obter o status.'
          this.status.color = 'red'
      }
    },
    settings (env) {
      this.environment = env

      this.wizard = true
    },
    health (env) {
      const cluster = this.clusters[env].filter(c => c.host === this.app.stages[env].cluster)

      if (cluster.length !== 1) {
        console.log('Cluster not found or duplicated:', cluster)

        return
      }

      this.$refs.health.open(this.project, this.app, env, cluster[0])
    },
    bug () {
      this.$refs.bug.open(this.app)
    },
    showStageStatus (env) {
      this.$refs.status.open(this.app, env, this.stageStatus[this.app.stages[env].status], this.project.maintainer)
    },
    color (text) {
      return stc(text)
    }
  }
}
</script>
