<template>
  <v-card :tile="$vuetify.breakpoint.xsOnly" class="d-flex flex-column">
    <v-card-title
      :class="(!accept ? 'error' : 'success') + ' white--text px-4'"
    >
      <span class="headline">{{ accept ? ($vuetify.breakpoint.xsOnly ? 'Configurar' : 'Configurar Ambiente de ' + environments[env].title) : 'Atenção!' }}</span>
      <v-spacer />
      <v-chip label style="font-family: monospace; font-weight: bold; word-spacing: -5px;" color="rgb(255,255,255,0.3)" class="white--text">{{ app.repository.split('/').join(' / ') }} @ {{ env }}</v-chip>
    </v-card-title>
    <v-stepper v-model="step" flat>
      <v-stepper-header>
        <v-stepper-step step="1" :rules="[() => accept]">
          Início
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2">
          Cluster
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3">
          Volumes
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4">
          Variáveis
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="5">
          URLs
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="6">
          Validar
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-window v-model="step">
      <v-window-item :value="1">
        <v-card-text class="pt-4 pb-0 subtitle-1">
          Esta ferramenta permite <strong>configurar e ativar um novo ambiente para <u>lançamento</u> da sua aplicação</strong>. Três tipos de lançamentos podem ser realizados:
          <strong>teste interno (<i>alpha</i>), teste externo (<i>beta</i>) e produção (<i>release</i>)</strong>.
        </v-card-text>
        <v-card-text class="pt-4 pb-0 subtitle-1">
          Cada <i>boilerplate</i>, escolhido no momento da criação da aplicação, exige um tipo específico de <i>pipeline</i>, um conjunto
          específico de variáveis injetadas e, em alguns casos, um arcabouço de tecnologias próprias do lado do servidor. Em função disso
          o servidor (ou <i>cluster</i>) para instanciar seu ambiente será determinado em função do tipo de ambiente (<i>alpha</i>, <i>beta</i>
          ou <i>release</i>) e do <i>boilerplate</i> escolhido.
        </v-card-text>
        <v-card-text class="pt-4 pb-0 subtitle-1">
          Para que o <i>pipeline</i> de entrega contínua do Embrapa I/O funcione corretamente, é fundamental que no
          <a :href="'https://git.embrapa.io/' + app.repository" target="_blank">repositório da aplicação no GitLab</a> exista a <i>branch</i> homônima (<strong><i>{{ env }}</i></strong>,
          neste caso). Além disso, é obrigatório que a revisão a ser entregue (<i>deploy</i>) tenha uma <i>tag</i> com o número da versão
          no padrão exigido pelo Embrapa I/O: <strong style="font-family: monospace;">V.AA.MM-{{ env !== 'release' ? env + '.' : '' }}P</strong>, onde <strong>V</strong> é a macro-versão, <strong>AA.MM</strong>
          é o ano e mês do <i>sprint</i> e <strong>P</strong> é o <i>patch</i> (p.e.,
          <strong>2.{{ new Date().getFullYear().toString().substring(2) }}.{{ (new Date().getMonth() + 1).toString() }}-{{ env !== 'release' ? env + '.' : '' }}7</strong>).
          A boa prática pede que este número de versão seja um <i>milestone</i> pré-cadastrado no <i>issue tracker</i> do
          <a :href="'https://git.embrapa.io/' + project.unix" target="_blank">GitLab do projeto</a>.
        </v-card-text>
        <v-card-text class="pt-4 pb-0 subtitle-1 font-weight-bold">
          Lembre-se: nossos recursos de hardware são limitados e é responsabilidade de todos os empregados o uso consciente!
        </v-card-text>
        <v-alert type="error" class="mx-4 mt-4" v-show="env === 'release'">
          <strong>Atenção!</strong> O Embrapa I/O está em "<i>Beta Release</i>", ou seja,
          ainda encontra-se em fase de desenvolvimento e homologação. Assim, é <strong>fortemente recomendado
          que você NÃO utilize seu serviço de hospedagem para aplicações em PRODUÇÃO</strong>. Para disponbilizar aplicações
          em produção que estejam na plataforma, <a href="https://www.embrapa.io/docs/releaser/" target="_blank" class="white--text">utilize a ferramenta
          <strong>Releaser</strong></a>!
        </v-alert>
        <v-card-actions class="py-0 px-6">
          <v-switch
            v-model="accept"
            label="Estou ciente e quero continuar."
            @change="() => {
              if (accept) load()
              else reset()
            }"
          />
        </v-card-actions>
      </v-window-item>

      <!-- Cluster -->
      <v-window-item :value="2">
        <v-card outlined color="white">
          <v-card-text class="pb-0">
            <v-autocomplete
              outlined
              label="Escolha o servidor/cluster..."
              :items="domains"
              v-model="environment.cluster"
              item-value="host"
              item-text="host"
              clearable
              :disabled="environment.deploy !== null"
              @change="changeCluster()"
              @click:clear="chosenCluster = null"
            >
              <template slot="selection" slot-scope="data">
                <v-list width="400px">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="font-family: monospace;" class="font-weight-bold">{{ data.item.host }}</v-list-item-title>
                      <v-list-item-subtitle>{{ data.item.local + ' • ' + data.item.location }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
              <template slot="item" slot-scope="data">
                <v-list width="400px">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="font-family: monospace;" class="font-weight-bold">{{ data.item.host }}</v-list-item-title>
                      <v-list-item-subtitle>{{ data.item.local + ' • ' + data.item.location }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
            </v-autocomplete>
          </v-card-text>
        </v-card>

        <v-card shaped color="blue-grey darken-2" class="mx-4 mb-6" dark v-if="chosenCluster" transition-group="fade-transition">
          <v-card-title class="overline pb-0">{{ chosenCluster.host }}</v-card-title>
          <v-card-text>
            Cluster na {{ chosenCluster.local }}, em {{ chosenCluster.location }}, com orquestrador '{{ chosenCluster.orchestrator }}' e
            storer '{{ chosenCluster.storage.type }}'.
          </v-card-text>
          <v-list color="blue-grey darken-1">
            <v-subheader>Aliases</v-subheader>
            <div class="px-3">
              <v-chip v-for="(alias, index) in chosenCluster.aliases" :key="index" label outlined dark class="mx-1 mb-2">{{ alias }}</v-chip>
            </div>
            <v-divider class="mt-2"></v-divider>
            <v-subheader>Recursos</v-subheader>
            <v-container>
              <v-row class="px-2">
                <v-col cols="6" md="4" class="pa-1" v-for="(resource, attr) in resources" :key="attr">
                  <v-card :color="resourceIsActive(chosenCluster, attr, resource) ? 'success' : 'error'" elevation="0">
                    <v-card-title>
                      <v-icon left>{{ resourceIsActive(chosenCluster, attr, resource) ? 'task_alt' : 'block' }}</v-icon>
                      <span class="text-body-2">{{ resource.label }}</span>
                      <v-spacer />
                      <v-btn icon x-small @click="$refs.info.open(resource.label, resource.info, 'Ok')"><v-icon>help</v-icon></v-btn>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <template v-if="resourceIsActive(chosenCluster, 'smtp', resources.smtp)">
              <v-divider class="mt-2"></v-divider>
              <v-subheader>SMTP Server</v-subheader>
              <v-container>
                <v-row class="px-2 py-0">
                  <v-col cols="12" md="8" class="px-1 py-0 ma-0">
                    <v-list-item class="px-1 py-0 ma-0">
                      <v-list-item-content>
                        <v-list-item-subtitle>Host</v-list-item-subtitle>
                        <v-list-item-title>{{ chosenCluster.resources.smtp.host }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="6" md="2" class="px-1 py-0 ma-0">
                    <v-list-item class="px-1 py-0 ma-0">
                      <v-list-item-content>
                        <v-list-item-subtitle>Porta</v-list-item-subtitle>
                        <v-list-item-title>{{ chosenCluster.resources.smtp.port }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="6" md="2" class="px-1 py-0 ma-0">
                    <v-list-item class="px-1 py-0 ma-0">
                      <v-list-item-content>
                        <v-list-item-subtitle>TLS</v-list-item-subtitle>
                        <v-list-item-title>{{ chosenCluster.resources.smtp.tls ? 'Sim' : 'Não' }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-list>
          <v-list color="blue-grey">
            <v-subheader>Mantenedores</v-subheader>
            <v-list-item v-for="(user, index) in chosenCluster.maintainers" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }} &bull; {{ user.phone }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn icon :href="'https://api.whatsapp.com/send?phone=' + user.phone.replace(/\D/g,'') + '&text=' + encodeURIComponent('Embrapa I/O: Preciso de informações sobre o cluster ' + chosenCluster.host + '!')" target="_blank"><v-icon>phone</v-icon></v-btn>
                <v-btn icon :href="'mailto:' + user.email" target="_blank"><v-icon>mail</v-icon></v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card>
      </v-window-item>

      <v-window-item :value="3">
        <wizard-environment-volume :environment="environment" :app="app" :project="project" :env="env" />
      </v-window-item>

      <!-- Env Vars -->
      <v-window-item :value="4">
        <wizard-environment-vars :environment="environment" :app="app" :project="project" :env="env" />
      </v-window-item>

      <!-- URLs -->
      <v-window-item :value="5">
        <v-card-text v-if="environment.cluster" class="pt-0">
          <wizard-environment-url :environment="environment" :app="app" :project="project" :env="env" :subdomains="subdomains" :step="step" />
        </v-card-text>
      </v-window-item>

      <!-- Run -->
      <v-window-item :value="6" class="mb-2">
        <v-card-text class="pt-4 pb-0 subtitle-1">
          <strong>Atenção!</strong> Ao clicar no botão abaixo as configurações do ambiente serão checadas e salvas. Neste momento,
          será realizado uma validação prévia e, caso sejam encontrados problemas, você poderá corrigir imediatamente.
        </v-card-text>
        <v-card-text class="pt-4 pb-0 subtitle-1">
          Uma vez que as configurações do ambiente tenham sido salvas, elas entrarão em uma fila para uma segunda validação.
          Você receberá um e-mail com o resultado. Caso seu conjunto de configurações esteja válido, você poderá
          efetuar o <i>deploy</i> da aplicação no ambiente. Para isso, crie uma <i>tag</i> na <i>branch</i> "<strong><i>{{ env }}</i></strong>"
          seguindo a nomenclatura recomendada para nomes de versões do Embrapa I/O: <strong>V.AA.MM-{{ env !== 'release' ? env + '.' : '' }}P</strong>, onde <strong>V</strong>
          é a macro-versão, <strong>AA.MM</strong> é o ano e mês do <i>sprint</i> e <strong>P</strong> é o <i>patch</i> (p.e.,
          <strong>2.{{ new Date().getFullYear().toString().substring(2) }}.{{ (new Date().getMonth() + 1).toString() }}-{{ env !== 'release' ? env + '.' : '' }}7</strong>).
          Para compreender este formato, leia sobre o padrão <a href="https://semver.org/" target="_blank">Semantic Versioning 2.0.0</a>.
        </v-card-text>
        <v-card-text class="pt-4 pb-0 subtitle-1">
          Sempre que precisar fazer uma alteração nestas configurações (tal como, adicionar um volume, alterar/incluir variáveis
          ou definir novas URLs) será realizado novamente o processo de validação.
        </v-card-text>
        <!--
        <v-card dark color="indigo lighten-1" class="mt-4 mb-2 mx-4">
          <v-card-title class="py-0 my-0 px-6">
            <v-switch v-model="deploy" color="orange lighten-1">
              <template v-slot:label @click.stop>
                <p class="pt-3 pl-2">
                  Se as configurações estiverem válidas, <strong>fazer o <i>deploy</i> para a última versão!</strong><br />
                  Ou seja, para a última <i>tag</i> da <i>branch</i> '<strong>{{ env }}</strong>'.
                </p>
              </template>
            </v-switch>
            <v-spacer />
            <v-icon x-large right>{{ deploy ? 'cloud_upload' : 'cloud_off' }}</v-icon>
          </v-card-title>
        </v-card>
        -->
      </v-window-item>
    </v-window>
    <v-alert color="error" icon="warning" dark v-show="error.active" class="mx-3">{{ error.message }}</v-alert>
    <v-card-actions v-if="step < 6">
      <v-btn
        color="error"
        text
        @click="cancel()"
        v-if="!$vuetify.breakpoint.xsOnly"
      >
        Cancelar
      </v-btn>

      <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />

      <v-btn
        color="warning"
        @click="back()"
        v-if="step > 1"
        text
      >
        <v-icon>arrow_back</v-icon>
        Voltar
      </v-btn>

      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />

      <v-btn
        color="primary white--text"
        depressed
        :disabled="!validate()"
        large
        :block="step === 1 && $vuetify.breakpoint.xsOnly"
        @click="next()"
        :loading="loading"
      >
        Próximo
        <v-icon class="ml-1">
          arrow_forward
        </v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="step === 6">
      <v-btn
        color="error"
        text
        @click="cancel()"
        v-if="!$vuetify.breakpoint.xsOnly"
        :disabled="saving"
      >
        Cancelar
      </v-btn>

      <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />

      <v-btn
        color="warning"
        @click="back()"
        text
        :disabled="saving"
      >
        <v-icon>arrow_back</v-icon>
        Voltar
      </v-btn>

      <v-btn
        color="success white--text px-4"
        depressed
        :disabled="!validate()"
        x-large
        @click="save()"
        :loading="saving"
      >
        Salvar e Validar
        <v-icon class="ml-3">
          rule
        </v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="$vuetify.breakpoint.xsOnly">
      <v-btn
        color="error"
        text
        @click="cancel()"
        :disabled="saving"
        block
      >
        Cancelar
      </v-btn>
    </v-card-actions>
    <dialog-wrapper ref="info" />
  </v-card>
</template>

<script>
import axios from 'axios'
import md5 from 'crypto-js/md5'

import ErrorHelper from '@/helpers/error'
import UtilHelper from '@/helpers/util'

import WizardEnvironmentVars from './WizardEnvironmentVars.vue'
import WizardEnvironmentVolume from './WizardEnvironmentVolume.vue'
import WizardEnvironmentUrl from './WizardEnvironmentUrl.vue'

import DialogWrapper from '@/components/DialogDefault.vue'

/*
draft
validating
valid
deploying
active
undeploying
inactivated
*/

export default {
  mixins: [
    ErrorHelper,
    UtilHelper
  ],
  components: {
    WizardEnvironmentVars,
    WizardEnvironmentVolume,
    WizardEnvironmentUrl,
    DialogWrapper
  },
  props: {
    project: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    app: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    env: {
      type: String,
      require: true,
      default: ''
    },
    boilerplates: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    },
    clusters: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    types: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    }
  },
  data: () => ({
    accept: false,
    step: 1,
    loading: false,
    saving: false,
    headers: {},
    domains: [],
    subdomains: [],
    environments: {
      alpha: {
        title: 'Testes Internos'
      },
      beta: {
        title: 'Testes Externos'
      },
      release: {
        title: 'Produção'
      }
    },
    environment: {
      status: 'DRAFT',
      cluster: '',
      version: 0,
      variables: [],
      volumes: [],
      urls: [],
      aliases: [],
      deploy: null
    },
    error: {
      active: false,
      message: ''
    },
    deploy: false,
    chosenCluster: null,
    resources: {
      smtp: { label: 'SMTP', default: false, info: '<p>Se o <i>cluster</i> possui <b>servidor de e-mail</b> disponível para envio de mensagens.<p>' },
      external: { label: 'IP Público', default: true, info: '<p>Se o <i>cluster</i> possui <b>IP público</b>, permitindo acesso externo diretamente às portas do host.</p><p>Útil para disponibilizar serviços não-HTTP, tal como um <i>broker</i> MQTT para IoT.</p><p>Caso não tenha, o acesso aos serviços das aplicações (portas expostas pela <i>stack</i> de containers) se dará somente via HTTPS nos <i>aliases</i> configurados (por meio do <i>proxy virtual</i> automaticamente provisionado pelo <b>Embrapa I/O</b>).</p>' },
      terminal: { label: 'Web Terminal', default: false, info: '<p>Se o <i>cluster</i> possui um <b>terminal web para acesso remoto ao <i>console</i> dos containers</b> (p.e., <i>bash</i>).</p><p>Útil para depuração de aplicações e eventual manutenção.</p>' },
      registry: { label: 'Registro', default: false, info: '<p>Se o <i>cluster</i> possui um <b><a href="https://distribution.github.io/distribution/" target="_blank">Distribution Registry</a></b> instalado localmente.</p><p>Útil para armazenar as imagens de containers geradas pelo processo de <i>build</i> do orquestrador.</p>' },
      backup: { label: 'Backup', default: false, info: '<p>Se o <i>cluster</i> realiza <b><i>backup</i> automatizado e individualizado dos containers</b>, permitindo recuperar o estado de uma aplicação.</p>' },
      snapshot: { label: 'Snapshot', default: true, info: '<p>Se é realizado no <i>cluster</i> um <i>backup</i> do tipo "<b><i>snapshot</i> da máquina virtual</b>".</p><p>Assim, em caso de um problema crítico, o <i>cluster</i> como um todo poderá ser recuperado.</p>' }
    }
  }),
  methods: {
    load () {
      this.error.active = false
      this.error.message = ''

      if (!navigator.onLine) {
        this.error.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error.active = true

        this.accept = false

        return
      }

      const err = error => {
        this.loading = false

        this.accept = false

        this.error.message = this.errorMessage(error)

        this.error.active = true
      }

      this.loading = true

      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }

      const api = process.env.VUE_APP_API

      axios.get(api + '/status', { timeout: 12000 }).then(response => {
        axios.get(api + '/build/' + this.app.repository + '/' + this.env, { headers: this.headers }).then(response => {
          this.environment = response.data

          this.domains = this.clusters[this.env]

          if (this.checkDomain(this.environment.cluster)) this.subdomains = this.domains.filter(i => i.host === this.environment.cluster)[0].aliases

          this.initializeVariables()

          this.loading = false
        }).catch(err)
      }).catch(err)
    },
    reset () {
      this.accept = false
      this.step = 1
      this.loading = false
      this.saving = false
      this.deploy = false

      this.domains = []
      this.subdomains = []

      this.chosenCluster = null

      this.environment = {
        status: 'DRAFT',
        cluster: '',
        version: 0,
        variables: [],
        volumes: [],
        urls: [],
        aliases: [],
        deploy: null
      }

      this.error.active = false
      this.error.message = ''
    },
    cancel () {
      this.reset()

      this.$emit('close')
    },
    validate () {
      switch (this.step) {
        case 1: return this.accept
        case 2: return this.environment.cluster && this.clusters[this.env].filter(i => i.host === this.environment.cluster).length === 1
        case 3: return true
        case 4: return true
        case 5: return true
        case 6: return true
      }

      return false
    },
    save () {
      this.error.active = false
      this.error.message = ''

      if (!navigator.onLine) {
        this.error.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error.active = true

        return
      }

      const err = error => {
        this.saving = false

        this.error.message = this.errorMessage(error)

        this.error.active = true
      }

      this.saving = true

      this.environment.urls.forEach((u, index, arr) => {
        if (!this.environment.variables.filter(i => i.type === 'PORT' && i.value === u.port).length) arr.splice(index, 1)
      })

      this.environment.aliases.forEach((a, index, arr) => {
        if (!this.environment.variables.filter(i => i.type === 'PORT' && i.value === a.port).length) arr.splice(index, 1)
      })

      this.environment.domains.forEach((d, index, arr) => {
        if (!this.environment.variables.filter(i => i.type === 'PORT' && i.value === d.port).length) arr.splice(index, 1)
      })

      this.environment.variables.forEach((a, index, arr) => {
        if (a.type === 'SERVER') arr.splice(index, 1)
      })

      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }

      const api = process.env.VUE_APP_API

      axios.get(api + '/status', { timeout: 12000 }).then(response => {
        axios.post(api + '/build/' + this.app.repository + '/' + this.env, this.environment, { headers: this.headers }).then(response => {
          this.$emit('message', 'Ambiente "' + this.env + '" para a app "' + this.app.repository + '" salvo com sucesso e enviado para validação!', 'success')

          this.$emit('refresh')

          this.reset()

          this.$emit('close')
        }).catch(err)
      }).catch(err)
    },
    async setPort (params) {
      const response = await axios.post(params.request, params.body, params.header)

      params.variable.value = response.data.port.toString()
    },
    initializeVariables (force = false) {
      if (!this.checkDomain(this.environment.cluster)) return

      if (force || (this.environment.status === 'DRAFT' && this.environment.version === 0)) {
        const api = process.env.VUE_APP_API

        const prefix = this.app.repository.replace('/', '_') + '_' + this.env + '_'

        const requests = []

        this.environment.variables.forEach((v, index, arr) => {
          switch (v.type) {
            case 'PASSWORD':
              if (!v.value || v.value.trim() === '') v.value = this.randomString(16)
              break

            case 'SECRET':
              if (!v.value || v.value.trim() === '') v.value = this.randomString(256)
              break

            case 'TEXT':
              if (!v.value || v.value.trim() === '') v.value = ''
              break

            case 'PORT':
              if (this.checkDomain(this.environment.cluster) && (!v.value || v.value.trim() === '')) {
                requests.push({
                  variable: v,
                  request: api + '/port/' + this.app.repository + '/' + this.env,
                  body: { cluster: this.environment.cluster, variable: v.name },
                  header: { headers: this.headers }
                })
              }
              break

            case 'VOLUME':
              if (!v.value.startsWith(prefix)) v.value = prefix + v.value
              break

            case 'EMPTY':
              v.value = ''
              break
          }
        })

        // Obtém as portas de forma sequencial (e não paralelamente)
        requests.reduce((acc, curr) => acc.then(() => {
          return this.setPort(curr)
        }), Promise.resolve())
      }

      const aux = this.app.repository.split('/')

      const unixP = aux[0]
      const unixA = aux[1]

      const date = new Date()

      const user = this.$localStorage.get('user')

      this.chosenCluster = this.clusters[this.env].filter(c => c.host === this.environment.cluster)[0]

      const replace = {
        '%SERVER%': this.environment.cluster,
        '%STAGE%': this.env,
        '%PROJECT_UNIX%': unixP,
        '%APP_UNIX%': unixA,
        '%VERSION%': '2.' + date.getFullYear().toString().substring(2) + '.' + (date.getMonth() + 1).toString() + '-' + (this.env !== 'release' ? this.env + '.' : '') + '7',
        '%DEPLOYER%': user.email,
        '%SENTRY_DSN%': this.app.bug ? 'https://' + this.app.bug.key + '@' + process.env.VUE_APP_SENTRY_HOST + '/' + this.app.bug.id : 'ERROR_TO_LOAD_SENTRY_DSN',
        '%MATOMO_ID%': this.app.hit ? this.app.hit.id : 'ERROR_TO_LOAD_MATOMO_ID',
        '%MATOMO_TOKEN%': md5(this.app.repository + '@' + this.env).toString()
      }

      const s = JSON.parse(JSON.stringify(this.types.filter(i => i.type === this.chosenCluster.orchestrator)[0].variables))

      const server = []

      for (const key in s) {
        for (const tag in replace) {
          s[key] = s[key].replace(tag, replace[tag])
        }

        server.push({ name: key, type: 'SERVER', value: s[key] })
      }

      this.environment.variables = server.concat(this.environment.variables.filter(i => i.type !== 'SERVER'))
    },
    changeCluster () {
      this.loading = true

      axios.delete(process.env.VUE_APP_API + '/port/' + this.app.repository + '/' + this.env, { headers: this.headers }).then(response => {
        this.environment.variables.forEach(v => { if (v.type === 'PORT') v.value = '' })

        if (this.checkDomain(this.environment.cluster)) {
          this.subdomains = this.domains.filter(i => i.host === this.environment.cluster)[0].aliases

          this.initializeVariables(true)
        }

        this.loading = false
      }).catch(err => {
        this.error.message = this.errorMessage(err)
        this.error.active = true
      })
    },
    next () {
      this.error.active = false
      this.error.message = ''

      this.step++
    },
    back () {
      this.error.active = false
      this.error.message = ''

      this.step--
    },
    resourceIsActive (cluster, attribute, resource) {
      if (!cluster.resources || !Object.hasOwn(cluster.resources, attribute) || !Object.hasOwn(cluster.resources[attribute], 'active')) return resource.default

      return cluster.resources[attribute].active
    }
  }
}
</script>
