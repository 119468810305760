<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" scrollable max-width="1000px">
      <v-card height="100%">
        <v-toolbar color="pink" dark flat>
          <v-toolbar-title>Histórico de Variáveis de Ambiente</v-toolbar-title>

          <v-progress-linear
            :active="syncing"
            :indeterminate="syncing"
            absolute
            bottom
            color="yellow"
          />

          <v-spacer />

          <v-chip v-if="environment" label style="font-family: monospace; font-weight: bold; word-spacing: -5px;" color="rgb(255,255,255,0.3)" class="white--text">{{ app.repository.split('/').join(' / ') }} @ {{ env }}</v-chip>
        </v-toolbar>

        <v-expansion-panels class="px-4 py-4">
          <v-expansion-panel v-for="(item, i) in history" :key="i">
            <v-expansion-panel-header>
              <span style="font-family: monospace;" class="font-weight-bold">Versão {{ i + 1 }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0 py-0">
              <v-textarea
                :value="stringify(item)"
                solo
                no-resize
                readonly
                dense
                flat
                outlined
                hide-details
                class="mx-0 my-0"
                style="font-family: monospace;"
                height="400"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-alert type="error" :value="error" transition="scale-transition" class="mx-4 mt-4">
          {{ message }}
        </v-alert>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

import ErrorHelper from '@/helpers/error'

export default {
  mixins: [
    ErrorHelper
  ],
  data () {
    return {
      dialog: false,
      user: null,
      environment: null,
      project: null,
      app: null,
      env: null,
      syncing: false,
      history: [],
      error: false,
      message: ''
    }
  },
  beforeMount () {
    this.user = this.$localStorage.get('user')
  },
  mounted () {
    if (this.user.authenticated) {
      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }
    }
  },
  methods: {
    open (environment, project, app, env) {
      this.environment = environment
      this.project = project
      this.app = app
      this.env = env

      this.reload()

      this.dialog = true
    },
    reload () {
      this.syncing = true

      this.error = false
      this.message = ''

      this.services = []

      const error = error => {
        this.error = true
        this.message = this.errorMessage(error)

        this.syncing = false
      }

      axios.get(process.env.VUE_APP_API + '/build/vars/' + this.app.repository + '/' + this.env, { headers: this.headers }).then(response => {
        this.history = response.data

        this.syncing = false
      }).catch(error)
    },
    stringify (vars) {
      const buffer = []

      for (let i = 0; i < vars.length; i++) buffer.push(vars[i].name + '=' + vars[i].value)

      return buffer.join('\n')
    }
  }
}
</script>
